import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next";
import Breadcrumbs from "components/Common/Breadcrumb";
import Select from "react-select";
import { Link } from "react-router-dom";


const Reports = props => {
    //meta title
   
    document.title = "Reports List | EventoX";
    const reportsList = [
        // {
        //     title: " Attendee list report",
        //     description:"Create a door list, ticket holder list or booking list with tickets purchased, contact details, sections and seats booked.",
        //     iconClass: "bx-copy-alt",
        //     link:"/attendee-report"
           
        // },
        {
            title: "View Complete booking status list",
            description:"Here we can view the complete booking status list including Total bookings, Total paid & unpaid bookings",
            iconClass: "bx-copy-alt",
            link:"/booking-status-report"
           
        },
        {
            title: " Event Summary Report",
            description:"The Event Summary report shows a summary of one or all of your events, including session times, sold tickets and available capacity",
            iconClass: "bx-copy-alt",
            link:"/event-summery-report"
           
        },
        {
            title: "Eventox Revenue Report",
            description:"The Event Summary report shows a summary of one or all of your events, sold tickets and revenue.",
            iconClass: "bx-copy-alt",
            link:"/eventox-revenue-report"
           
        },
       
        // {
        //     title: "View Company Revenue Report",
        //     description:"Here we can view Total revenue,Revenue by event and Revenue by ticket type",
        //     iconClass: "bx-copy-alt",
        //     link:"/booking-status-report"
           
        // },


    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Dashboards")}
                        breadcrumbItem={props.t("Dashboard")}
                    />

                    <Row>
                        {reportsList.map((report, key) => (
                            <Col xl={3} md={6} key={key}>
                                    <Card>
                                        <CardBody>
                                            <div className="favorite-icon">
                                                <Link to="#"><i className="uil uil-heart-alt fs-18"></i></Link>
                                            </div>
                                            <h5 className="fs-17 mb-2">
                                                <Link to="/job-details" className="text-dark">{report.title}</Link> </h5>
                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item">
                                                    <p className="text-muted fs-14 mb-1">{report.description}</p>
                                                </li>{" "}
                                               
                                              
                                            </ul>
                                          
                                            <div className="mt-4 hstack gap-2">
                                            <Link to={report.link}  className="btn btn-soft-primary w-100">View</Link>

                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                        ))}
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
};



export default withTranslation()(Reports);

