import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next";
import { getAllEvents, getAllOrganizer, getBookingByEventId, getForumBySlug } from "api";
import Breadcrumbs from "components/Common/Breadcrumb";
import Select from "react-select";
import TableContainer from "../../../components/Common/TableContainer";
import moment from "moment";

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { filter } from "lodash";

const pagination = true;
const paginationPageSize = 500;
const paginationPageSizeSelector = [200, 500, 1000];
const RevenueReport = props => {
    //meta title
    document.title = "Revenue Report | EventoX";
    const [eventList, setEventList] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [bookingList, setBookingList] = useState([])
    const [gridParams, setGridParams] = useState(null)
    const [gridApi, setGridApi] = useState(null);
    const gridApiRef = useRef(null);


    const [reports, setReports] = useState([
            { title: "Total Collected Amount", iconClass: "bx-copy-alt", description: 0,col:3 },
            { title: "Total Ticket Sales", iconClass: "bx-copy-alt", description: 0,col:3 },
            { title: "Total Discount", iconClass: "bx-copy-alt", description: 0,col:3 },
            { title: "Total Booking", iconClass: "bx-archive-in", description: 0,col:3 },

            { title: "Organizer Revenue", iconClass: "bx-purchase-tag-alt", description: 0, col:4},
            { title: "Eventox Revenue", iconClass: "bx-purchase-tag-alt", description: 0, col:4},
            { title: "Strip Payment Fee", iconClass: "bx-purchase-tag-alt", description: 0, col:4},



            


    ])

    useEffect(() => {
        fetchAll()
    }, [])

    const fetchAll = async () => {
        try {

            const respAllEvent = await getAllEvents();
            const eventOptions = respAllEvent.map((item) => {
                return {
                    value: item.eventId,
                    label: item.eventName,
                    ticketFee: item.ticketFee,
                }
            })
            setEventList(eventOptions);





        } catch (error) {
            console.log(error);
        }
    }

    const fetchBookingByEvent = async (data) => {
        const { value } = data;
        const getBookingResp = await getBookingByEventId(value)
        let summaryData=[];
        let totalEventoxFee=0;
        let totalStripeFee=0;   
        let totalTicketAmount=0;
        let totalDiscountAmount=0;
        let totalTicketSold=0;
        let totalGrandTotal=0;
        let totalSuccessBooking=0;
        getBookingResp.forEach(item => {
            if(item.bookedBy === '0'&&item.bookingStatus === 'confirmed'){
                console.log("🚀 ~ getBookingResp.forEach ~ item:", item)
                const grandTotal = item.grandTotal;
                const ticketAmount = item.totalTicketAmount;
                const discountAmount = item.discountAmount?item.discountAmount:0;
                const stripefee=((grandTotal/100)*2.7)+0.30;
                const eventoxRevenue=grandTotal-stripefee-ticketAmount;
                totalStripeFee+=stripefee;
                totalEventoxFee+=eventoxRevenue;
                totalTicketAmount+=ticketAmount;
                totalDiscountAmount+=discountAmount;
                totalTicketSold+=item?.totalTicketCount;
                totalGrandTotal+=item.grandTotal;
                totalSuccessBooking+=1;


                summaryData.push({
                    userDetails:{
                        name:item?.bookingObject?.primaryContactDetails?.firstName + ' ' + item?.bookingObject?.primaryContactDetails?.lastName,
                        email:item?.bookingObject?.primaryContactDetails?.email,
                        phone:item?.bookingObject?.primaryContactDetails?.phoneNo,
                    },
                    ticketDetails:{
                        ticketName:item?.TicketType?.ticketType,
                        ticketPrice:item?.TicketType?.price,
                       
                    },
                    ticketSold:item?.totalTicketCount,
                    discount:`$${discountAmount.toFixed(2)}`,
                    stripeFee:`$${stripefee.toFixed(2)}`,
                    eventoxRevenue:`$${eventoxRevenue.toFixed(2)}`,
                    organizerRevenue:`$${ticketAmount.toFixed(2)}`,
                    grandTotal:`$${grandTotal.toFixed(2)}`,
                    createdAt:moment(item.createdAt).format('DD-MMM-YYYY HH:mm:ss')
                })
            }
        })
        



        setBookingList(summaryData)
        console.log("🚀 ~ fetchBookingByEvent ~ summaryData:", summaryData)
        setReports([
            { title: "Total Collected Amount", iconClass: "bx-copy-alt", description: `$${totalGrandTotal.toFixed(2)}`,col:3 },
            { title: "Total Sales", iconClass: "bx-copy-alt", description: `$${totalTicketAmount.toFixed(2)}`,col:3 },
            { title: "Total Discount", iconClass: "bx-copy-alt", description: `$${totalDiscountAmount.toFixed(2)}`,col:3 },
            { title: "Total Success Booking", iconClass: "bx-archive-in", description: totalSuccessBooking,col:3 },

            { title: "Organizer Revenue", iconClass: "bx-purchase-tag-alt", description: `$${totalTicketAmount.toFixed(2)}`, col:4},
            { title: "Eventox Revenue", iconClass: "bx-purchase-tag-alt", description: `$${totalEventoxFee.toFixed(2)}`, col:4},
            { title: "Strip Payment Fee", iconClass: "bx-purchase-tag-alt", description: `$${totalStripeFee.toFixed(2)}`, col:4},
            


           

        ])


    }
    const columns =  [
            
            {
                headerName: 'User Details',
                field: 'userDetails',
                cellRenderer: params => {
                    return <div>
                        <p>{params.data.userDetails.name}</p><br/>
                        <p>{params.data.userDetails.email}</p><br/>
                        <p>{params.data.userDetails.phone}</p>
                    </div>
                },

            },
            {
                headerName: 'Ticket Details',
                field: 'ticketDetails',
                cellRenderer: params => {
                    return <div>
                        <p>{`${params.data.ticketDetails.ticketName} - $${params.data.ticketDetails.ticketPrice}`}</p><br/>
                      
                    </div>
                },
                filter: true

            },
            {
                headerName: 'Ticket Sold',
                field: 'ticketSold',
                cellRenderer: params => {
                    return <div>
                        <p>{`${params.data.ticketSold} tickets sold`}</p>
                    </div>
                },
                filter: true,
                
            },
            {
                headerName: 'Discount Amount',
                field: 'discount',
                filter: true,
                

            },
           
            {
                headerName: 'Stripe Fee',
                field: 'stripeFee',
                filter: true,

            },
            {
                headerName: 'Eventox Revenue',
                field: 'eventoxRevenue',
                filter: true,

            },
            {
                headerName: 'Organizer Revenue',
                field: 'organizerRevenue',
                filter: true,

            },
            {
                headerName: 'Grand Total',
                field: 'grandTotal',
                filter: true,

            },


           
            {
                headerName: 'Created At',
                field: 'createdAt',
                filter: true,
                sortable: true,

            },



        ]



    const onGridReady = (params) => {
        gridApiRef.current = params.api // <= assigned gridApi value on Grid ready 
        setGridParams(params)
        setGridApi(params.api);

    }
    const getContextMenuItems = (params) => {
        const result = [
          'copy',
          'copyWithHeaders',
          'paste',
          'separator',
          {
            name: 'Export All',
            action: () => {
              params.api.exportDataAsExcel();
            },
            icon: '<i class="fas fa-file-excel"></i>', // Optional: add an icon for the menu item
          },
        ];
        return result;
      };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Dashboards")}
                        breadcrumbItem={props.t("Dashboard")}
                    />

                    <Row>
                        <Col xl="12">
                            <Row>
                                {(reports || [])?.map((report, key) => (
                                    <Col md={report.col} key={"_col_" + key}>
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">
                                                            {report.title}
                                                        </p>
                                                        <h4 className="mb-0">{report.description}</h4>
                                                    </div>
                                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            <i className={"bx " + report.iconClass + " font-size-24"}></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">

                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xl="5">
                                            <div className="col-md-12">
                                                <Select
                                                    value={selectedEvent}
                                                    placeholder="Select Event"
                                                    onChange={(data) => {
                                                        setBookingList([])
                                                        setSelectedEvent(data)
                                                        fetchBookingByEvent(data)
                                                    }}
                                                    options={eventList}
                                                    width="100%"
                                                    className="select2-selection"
                                                />

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div style={{
                                            height: '500px',
                                            width: '100%'
                                        }} className="ag-theme-alpine">
                                            <AgGridReact
                                                columnDefs={columns}
                                                rowData={bookingList}
                                                onGridReady={onGridReady}
                                                getRowId={({ data }) => data?.bookingNo?.toString()}
                                                ref={gridApiRef}
                                                defaultColDef={{
                                                    floatingFilter: true,
                                                    filter: 'agTextColumnFilter',
                                                    sortable: true,
                                                    resizable: true,
                                                    enableRowGroup: true,
                                                }}
                                                getContextMenuItems={getContextMenuItems}
                                                sideBar={{ toolPanels: ['columns', 'filters'] }}
                                                rowGroupPanelShow="always"
                                                pagination
                                                paginationPageSize={paginationPageSize}
                                                paginationPageSizeSelector={paginationPageSizeSelector}
                                                suppressRowClickSelection={true}
                                               
                                               

                                            />
                                        </div>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};



export default withTranslation()(RevenueReport);

